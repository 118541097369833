
  import { Component } from 'vue-property-decorator'
  import { Expandable } from '@/components/toolkit/details/row/custom/Expandable'
  import { LeadActivity } from '@/entities/crm'

@Component
  export default class LeadActivityHistory extends Expandable {
    activities: LeadActivity[] = []

    // Methods
    async mounted () {
      if (!this.value?.id) return
      this.activities = await this.fetchData({
        query: { name: 'find', model: 'LeadActivity', order: { id: 'desc' } },
        filter: {
          id_lead: { _eq: this.value.id },
        },
        force: true,
      })
    }

    result (closingReason) {
      return !closingReason?.id ? 'Pendiente' : closingReason?.description
    }

  getNote (notes, type) {
    return notes?.find(note => note.deal?.type?.name === type)?.note || 'Sin notas'
  }

  get hasSale () {
    return this.value.deals.some(deal => deal.type.name === 'sale')
  }

  get hasPurchase () {
    return this.value.deals.some(deal => deal.type.name === 'purchase')
  }

    // Getters
    get leadActivities () {
      const { activities } = this
      return activities?.length ? activities : []
    }
  // Watchers
  }
